
.ImgAdvt {
  margin-left: 20px;
  align-items: center;
   width:70%;
   height:50%;
   margin:auto
}

.DivAdvt{

  align-items: center;
  margin-left: 350px;

}