@import url('https://fonts.googleapis.com/css2?family=Maven+Pro&display=swap');

.marquee {
  width: 100%;
  margin-top: 5px;
  border: 1px solid rgb(255, 255, 255);
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  font-size: larger;
  color: blue;
}

.marquee span {
  display: inline-block;
  animation: marquee 25s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(80vw);
  }
  100% {
    transform: translateX(-100%);
  }
}