@import url('https://fonts.googleapis.com/css2?family=Maven+Pro&display=swap');

body {
    font-family: 'Maven Pro', sans-serif;
    background-color: #f7f4f5
}
hr {
    color: #0000004f;
    margin-top: 5px;
    margin-bottom: 5px
}
.add td {
    color: #c5c4c4;
    text-transform: uppercase;
    font-size: 12px
}
.content {
    font-size: 14px
}